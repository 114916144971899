<template>
  <div class="mx-5 h-100 d-flex flex-column justify-center align-center">
    <ion-img class="w-75 mx-auto" :src="drawer_welcome" />
    <ion-label class="text-center fw-600 fs-3 mt-5 mb-3">{{ $t('nothing_here_yet') }}</ion-label>
    <ion-button v-if="useAction" fill="outline" class="mt-2 w-100 text-capitalize" @click="$router.back()">
      {{ $t('back_to_notifications') }}
    </ion-button>
  </div>
</template>
<script>
import drawer_welcome from '@/assets/icons/shared/drawer_welcome.svg';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotFoundNotification',
  emits: ['callbackAction'],
  props: {
    useAction: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup() {
    return {
      drawer_welcome
    };
  }
});
</script>
